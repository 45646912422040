<script>
import { api } from "@/state/services";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
    };
  },
  methods: {
    resendToken() {
      api
        .post('email/resend')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.patrimony = response.data.total.patrimony
          }
        })
    },
  },
  mounted() {
  }
};
</script>

<template>
  <div class="d-flex align-items-center justify-content-center">
    <div class="p-3">
      <div class="text-center mb-5">
        <img class="w-100" style="max-width: 250px" src="@/assets/images/logo.png" />
      </div>
      <div class="card">
        <div class="card-header rounded-top">
          <h5 class="card-title mb-0 text-center">{{ t("Email Validation") }}</h5>
        </div>
        <div class="card-body">
          <div class="mb-3">
            {{ t("To ensure your account security, we send a validation link to your email address.") }}
          </div>
          <div class="mb-3 text-center">
            <strong>{{ account.user.email.address }}</strong>
          </div>
          <div class="mb-3">
            {{ t("Please check your inbox and click the link to validate your email.") }}
          </div>
          <hr>
          <div>
            <button class="btn btn-link" v-on:click="resendToken(); this.$noty.success('Sent!')">
              {{ t("Didn't receive the email?") }}<br>
              {{ t("Click here to resend.") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.d-flex {
  max-width: 400px;
  margin: 0 auto;
  height: 100vh !important;
}
</style>
